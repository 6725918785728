import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {INotification, Notification} from './Notification';
import styles from './notifications.module.scss';
import {INotify} from "./NotificationManager";

export default function Notifications(props: {
    notifications: INotify[],
    onRequestHide: (notification: INotification) => void,
    enterTimeout: number,
    leaveTimeout: number,
}) {
    const {notifications = [], onRequestHide = () => {}, enterTimeout = 400, leaveTimeout = 400} = props;

    function handleRequestHide(notification) {
        onRequestHide(notification);
    }

    const items = notifications.map((notification, i) => {
        const key = notification.id || new Date().getTime();
        return (
            <CSSTransition
                key={i}
                classNames={{
                    enter: styles.notificationEnter,
                    enterActive: styles.notificationEnterActive,
                    exit: styles.notificationLeave,
                    exitActive: styles.notificationLeaveActive,
                }}
                timeout={{ enter: enterTimeout, exit: leaveTimeout }}
            >
                <Notification
                    key={key}
                    type={notification.type}
                    title={notification.title}
                    message={notification.message}
                    timeOut={notification.timeOut}
                    onClick={notification.onClick}
                    onRequestHide={() => handleRequestHide(notification)}
                />
            </CSSTransition>
        );
    });
    return (
        <div className={styles.notificationContainer}>
            <TransitionGroup>
                {items}
            </TransitionGroup>
        </div>
    );
}
