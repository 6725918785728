import * as React from 'react';
import {useContext} from 'react';
import {StoreContext} from "../../stores/StoreLoader";
import {observer} from "mobx-react";
import styles from "./styles/SchoolBlocksHeader.module.scss";
import dynamic from "next/dynamic";

const SimpleAndFloating = dynamic(() => import("./SchoolBlocksHeaderThemes/SimpleAndFloating"));
const MinimalTheme = dynamic(() => import("./SchoolBlocksHeaderThemes/MinimalTheme"));

const SchoolBlocksHeader: React.FC<{
    hideNavigation?: boolean,
    hideLoginButton?: boolean,
    mainUrl: string,
    preventTabbing: boolean
}> = observer(props => {
    const {styleStore, modalStore, organizationStore, interfaceStore} = useContext(StoreContext);

    function openLoginModal() {
        modalStore.addModal({
            type: 'login',
            id: 'login-modal',
        })
    }

    function toggleUserMenu() {
        interfaceStore.toggleUserMenu();
    }

    const hideLoginButton = organizationStore.organization.json_data?.settings?.appearance?.hideLoginButton || props.hideLoginButton;

    const theme = (styleStore.themeID === "1" || styleStore.themeID === "2" ?
        <SimpleAndFloating
            toggleUserMenu={toggleUserMenu}
            openLoginModal={openLoginModal}
            mainUrl={props.mainUrl}
            hideNavigation={props.hideNavigation}
            hideLoginButton={hideLoginButton}
            preventTabbing={props.preventTabbing}
        />
        :
        <MinimalTheme
            toggleUserMenu={toggleUserMenu}
            openLoginModal={openLoginModal}
            mainUrl={props.mainUrl}
            hideLoginButton={hideLoginButton}
        />
    );

    return <div className={styles.centerContent}>
        {theme}
    </div>
})

export default SchoolBlocksHeader;
