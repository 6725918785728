import { useState, useEffect } from 'react';
import useClickOutside from "./useClickOutside";

export function useComponentVisible(initialIsVisible?: boolean, escCallBackFunction?: () => void) {
    const [isComponentVisible, setIsComponentVisible] = useState<boolean | undefined>(initialIsVisible);
    const ref = useClickOutside(() => setIsComponentVisible(false));

    const handleHideDropdown = (event) => {
        if (event.key === 'Escape') {
            setIsComponentVisible(false);
            if (escCallBackFunction) {
                escCallBackFunction()
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}
