import FontAwesome from "../../../utilities/FontAwesome";
import styles from "./styles/dropdown.module.scss";
import classNames from 'classnames';
import NavigationLink from "../NavigationLink";
import TitleWithPopoutIcon from "../TitleWithPopoutIcon";

const dropdownIconClassName = classNames({
    [styles.dropdownIconLink]: true,
    ['nav-dropdown-item']: true,
});

export default function DropdownList(props) {
    const {url, title, description, icon, children} = props;

    if (props.header) {
        return <li role={"none"} style={{maxHeight: props.maxHeight}}>
            <h2 tabIndex={'-1'} className={'nav-dropdown-item nav-dropdown-header'}>{props.header}</h2>
            <ul role={"menu"} aria-label={props.header}>
                {children
                    .filter(child => child.enabled)
                    .map((child) => {
                        return (
                            <li key={child.title + '-' + child.id} role={"none"}>
                                <NavigationLink navigationObj={child} role={"menuitem"} className={'nav-dropdown-item'}>
                                    <TitleWithPopoutIcon url={child.url}>
                                        {child.title}
                                    </TitleWithPopoutIcon>
                                </NavigationLink>
                            </li>
                        );
                    })}
            </ul>
        </li>
    } else {

        return <li role={'none'} style={{maxHeight: props.maxHeight}}>
            <NavigationLink navigationObj={props} role={"menuitem"} className={dropdownIconClassName}>
                <div>
                    <FontAwesome ariaHidden={true} name={icon}/>
                </div>
                <div>
                    <TitleWithPopoutIcon url={url}>
                        <h3>{title}</h3>
                    </TitleWithPopoutIcon>
                    <span>{description}</span>
                </div>
            </NavigationLink>
        </li>
    }
}
