import {useContext} from "react";
import {observer} from "mobx-react";
import styles from "./styles/NavigationSecondary.module.scss";
import classNames from "classnames";
import MegaNav from "./navigation/meganav/MegaNav";
import {StoreContext} from "../../stores/StoreLoader";
import {useGridType} from "../../hooks/useGridType";
import {GridTypes} from "../../pages/MainGridPage";

const NavigationSecondary = observer((props: {
    isWelcomePage: boolean,
    visible: boolean,
    heroIsEnabled: boolean,
}) => {
    const {styleStore, organizationStore} = useContext(StoreContext);
    const gridType = useGridType();

    const secondaryClassName = classNames({
        [styles.secondary]: true,
        [styles.secondaryFloatingBlocks]: styleStore.themeID === "2",
        [styles.secondarySubOrg]: organizationStore.organization.id !== organizationStore.currentOrganization.id &&
        gridType === GridTypes.terraced && props.heroIsEnabled,
        [styles.secondaryVisible]: props.visible,
        ["sb-secondary-nav sb-organization-color-secondary-nav clearfix"]: !props.isWelcomePage,
        ["sb-navbar-secondary-container sb-content-width-plus-margin"]: !props.isWelcomePage && styleStore.themeID === "2",
    });

    return (
        <div className={secondaryClassName} id={"sb-secondary-nav"}>
            <MegaNav />
        </div>
    )
})

export default NavigationSecondary;
