import {useContext, useEffect, useRef, useState} from "react";
import type {SyntheticEvent} from "react";
import classNames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";
import { observer } from "mobx-react";

import FontAwesome from "../../utilities/FontAwesome";
import { StoreContext } from "../../../stores/StoreLoader";
import styles from "../styles/LanguageTool.module.scss";
import NotificationManager from "../../notifications/NotificationManager";
import i18n from "../../../internationalization/i18n";

const DropDownItems = observer((props: {
    items: Array<string>
}) => {
    const {i18nStore} = useContext(StoreContext);

    const currentSupportedLocale = i18nStore.localeSetByUser || i18nStore.currentAppSupportedLocale;

    return <>
        {
            props.items.map((locale) => {
                const lang = i18nStore.getLanguage(locale);
                const active = (locale === currentSupportedLocale);
                const checkBoxIconClassName = classNames({
                    [styles.checkBoxIcon]: true,
                    [styles.transparent]: !active,
                });
                return <Dropdown.Item bsPrefix={styles.dropdownItem + " notranslate dropdown-item"}
                                      eventKey={locale}
                                      active={active}
                                      key={locale}
                >
                    <FontAwesome prefix={"fas"} name={"fa-check"} className={checkBoxIconClassName}/>
                    {lang}
                </Dropdown.Item>
            })
        }
    </>;
});

const SupportedDropDownItems = observer(() => {
    const {i18nStore} = useContext(StoreContext);

    const ssLocales = i18nStore.organizationSupportedLocales;
    const asLocales = i18nStore.appSupportedButNotorganizationSupportedLocales;

    return <>
        {/* school supported */}
        <DropDownItems items={ssLocales} />
        {/* app supported */}
        <DropDownItems items={asLocales} />
    </>;
})

const GoogleDropDownItems = observer(() => {
    const {i18nStore} = useContext(StoreContext);

    const currentLocale = i18nStore.localeSetByUser;
    const [googleItems, setGoogleItems] = useState<Array<JSX.Element | undefined>>([]);
    const masterTimeoutFinished = useRef(false);

    useEffect(() => {
        function getGoogleLanguages() {
            const firstValue = document.querySelector(".goog-te-combo option:nth-child(1)");
            if (masterTimeoutFinished.current) {
                NotificationManager.error("Google Translate failed to load! Some translation features may not be available.");
                return;
            } else if (!firstValue) {
                setTimeout(getGoogleLanguages, 100);
            } else {
                const googleDropDownItems = i18nStore.unsupportedLocales.map((loc: string) => {
                    const query = "select > option[value='" + loc + "']";
                    const el = document.querySelector(query);
                    if (el !== null) {
                        const active = (loc === currentLocale);
                        const checkBoxIconClassName = classNames({
                            [styles.checkBoxIcon]: true,
                            [styles.transparent]: !active,
                        });
                        const language = el.textContent;
                        return <Dropdown.Item eventKey={loc}
                                              key={loc}
                                              bsPrefix={styles.dropdownItem  + " notranslate dropdown-item"}
                                              active={active}
                        >
                            <FontAwesome prefix={"fas"} name={"fa-check"} className={checkBoxIconClassName}/>
                            {language}
                        </Dropdown.Item>;
                    }
                });

                setGoogleItems(googleDropDownItems);
            }
        }

        getGoogleLanguages();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            masterTimeoutFinished.current = true;
        }, 5000);
    }, []);

    return <>{googleItems}</>;
});

export const LanguageDropDown = observer((props: {
    handleSelect?: (e?: SyntheticEvent<unknown>) => void,
}) => {
    const {i18nStore} = useContext(StoreContext);

    const handleSelect = (e) => {
        i18nStore.setLocaleByUser(e);
        if (typeof props.handleSelect === 'function') {
            props.handleSelect(e);
        }
    }

    useEffect(() => {
        if (i18nStore.currentOrgSupportedLocale) {
            i18n.changeLanguage(i18nStore.currentOrgSupportedLocale)
        }
    }, [i18nStore.currentOrgSupportedLocale])

    return <Dropdown onSelect={handleSelect} focusFirstItemOnShow={true}>
        <Dropdown.Toggle className={styles.dropdownButton + " notranslate"} id={"languageDropDownToggle"} variant={""}>
            {i18nStore.currentLanguageString}
            <FontAwesome prefix={"fas"} name={"fa-sort-down"} className={styles.sortDown}/>
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.dropdownMenu} role={"menu"}>
            <SupportedDropDownItems/>
            <GoogleDropDownItems/>
        </Dropdown.Menu>
    </Dropdown>
})
