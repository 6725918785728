import { Component } from 'react';
import {autorun} from 'mobx';

import {StoreContext} from "../../stores/StoreLoader";
import {ExecutionEnvironment} from "../../stores/InterfaceStore";
import {getConfig} from "../../_configs/AppConfig";

const {publicRuntimeConfig} = getConfig();

export default class HelpScout extends Component {
    static contextType = StoreContext;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.initAutorunDisposer = autorun(() => {
            if (this.context.userStore.supportChatEnabled && ExecutionEnvironment.canUseDOM) {
                if (typeof window.Beacon !== 'function') {
                    this.loadSDKAsynchronously();
                    this.beaconInit();
                }

                if (this.context.userStore.supportChatOpen) {
                    this.showWidget();
                }
            }
        });
    }

    componentWillUnmount() {
        this.initAutorunDisposer();
    }

    beaconInit() {
        const {userStore} = this.context;

        window.Beacon('init', publicRuntimeConfig.HELP_SCOUT.apiKey);
        window.Beacon('config', {
            messaging: {
                contactForm: {
                    showGetInTouch: true,
                },
            },
        });
        window.Beacon('on', 'close', () => {
            userStore.closeSupportChat();
        })

        const userObj = {
            name: userStore.firstName + " " + userStore.lastName,
            email: userStore.username,
            signature: userStore.supportChatKey || undefined,
        }
        // HelpScout can only handle a max of 200 characters in this field
        if (typeof userStore.avatar?.length === "function" && userStore.avatar.length() < 200) {
            userObj.avatar = userStore.avatar;
        }

        window.Beacon('identify', userObj);
        window.Beacon('prefill', userObj);
    }

    loadSDKAsynchronously() {
        !function (e, t, n) {
            function a() {
                var e = t.getElementsByTagName("script")[0], n = t.createElement("script");
                n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e)
            }

            if (e.Beacon = n = function (t, n, a) {
                e.Beacon.readyQueue.push({method: t, options: n, data: a})
            }, n.readyQueue = [], "complete" === t.readyState) return a();
            e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1)
        }(window,document,window.Beacon||function(){});
    }

    showWidget = () => {
        window.Beacon('open')
    }

    render() {
        return <div />
    }
}
