import {useContext, SetStateAction} from "react";
import {StoreContext} from "../../../stores/StoreLoader";
import styles from "./Microsite.module.scss";
import FontAwesome from "../../utilities/FontAwesome";
import ClickableLink from "../../utilities/ClickableLink";
import {IMicrositeLink} from "./Microsite";
import classNames from 'classnames';

export function MicrositeNavigation(props: {
    link: IMicrositeLink,
    micrositeOpen: boolean,
    setMicrositeOpen: SetStateAction<any>,
    index: number,
}) {
    const {organizationStore} = useContext(StoreContext);

    function handleClick() {
        if (props.micrositeOpen) {
            props.setMicrositeOpen(false);
        }
    }

    const linkClassName = classNames({
        [styles.link]: true,
        [styles.linkIsCurrentPage]: props.link.model?.id === organizationStore.currentOrganization.id,
    })

    return <>
        <li className={linkClassName}>
            <ClickableLink
                href={props.link.url}
                onClick={handleClick}
            >
                <span>{props.link.label}</span>
                <FontAwesome name={'fa-angle-double-right'} prefix={'fas'}/>
            </ClickableLink>
            {props.link.children?.length > 0 && <ul>
                {props.link.children.map((link, index) => <MicrositeNavigation
                    key={link.id}
                    link={link}
                    index={index}
                    micrositeOpen={props.micrositeOpen}
                    setMicrositeOpen={props.setMicrositeOpen}
                />)}
            </ul>}
        </li>
        {props.link.children?.length > 0 && <span style={{display: "none"}}/>}</>
}