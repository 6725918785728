import React, {useContext} from 'react';
import {StoreContext} from "../../../../stores/StoreLoader";
import Button from "../../../utilities/Button";

function FollowingButton(props) {
    const {sidebarStore} = useContext(StoreContext);

    return (
        <Button onClick={() => {
            sidebarStore.setSidebar({
                view: "Following"
            })
        }} {...props}>{props.children}</Button>
    );
}

export default FollowingButton;
