import {useContext} from "react";
import {StoreContext} from "../stores/StoreLoader";
import {TitleOrganizationTypes} from "../types/OrganizationTypeValues";

/*
Returns true if the current site is allowed to provide an app - currently allowed for top level orgs and districts
that have added the "app_plus" billing product. Note that this is not the same as having enabled the app - this is a
separate option.
 */
export function useAppAllowed() {
    const {organizationStore} = useContext(StoreContext);

    // only top level organizations can be apps OR those with app_plus billing product
    return TitleOrganizationTypes.includes(organizationStore.organization.type);
}