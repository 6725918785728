import {observer} from "mobx-react";
import { useContext } from 'react';
import * as React from 'react';
import Button from "../../utilities/Button";
import ClickableLink from "../../utilities/ClickableLink";
import {StoreContext} from "../../../stores/StoreLoader";
import {withLocaleInURI} from "../../../internationalization/i18nURI";
import {getDistrict} from "../../../utils/SchoolBlocksUtilities";

const NavigationLink: React.FC<{
    navigationObj: Navigation,
    href?: string,
    [restProps: string]: any,
}> = observer((props) => {
    const {i18nStore, modalStore, organizationStore} = useContext(StoreContext);
    const {navigationObj, href, ...restProps} = props;

    const isButtonType = props.navigationObj.type === 4;


    if (isButtonType) {
        let orgId = navigationObj.organization_id;

        let blockModel: BaseOrganization;
        if (orgId === organizationStore.organization.id) {
            blockModel = organizationStore.organization as BaseOrganization;
        } else blockModel = getDistrict(organizationStore.organization) as BaseOrganization;

        return <Button {...restProps} onClick={() => modalStore.addModal({
            type: "schoolDistrict",
            orgId,
            id: orgId,
            blockObj: {
                id: orgId,
                blockType: blockModel?.type,
                blockModel,
            },
        } as ISchoolDistrictOrganizationModalObj)}>
            {props.children}
        </Button>
    } else {
        let hrefWithCurrentLocale = href || navigationObj?.url || "#0";
        if ((href || navigationObj?.url) && navigationObj.organization_id) {
            // this is an internal page/link, so we need to make sure we use the current language
            hrefWithCurrentLocale = withLocaleInURI(hrefWithCurrentLocale, i18nStore.currentOrgSupportedLocale);
        }

        return <ClickableLink href={hrefWithCurrentLocale} prependLocale={false} {...restProps}>
            {props.children}
        </ClickableLink>
    }
});

export default NavigationLink;
