import * as React from 'react';
import styles from './styles/BigRoundedButton.module.scss';
import classNames from 'classnames';
import Button from "../../utilities/Button";

export default function BigRoundedButton(props: {
    className?: string,
    onClick: React.MouseEventHandler,
    disabled?: boolean,
    children: React.ReactNode,
}) {
    const BigRoundedButtonClassName = classNames({
        [styles.bigRoundedButton]: true,
        [styles.bigRoundedButtonDisabled]: props.disabled,
        [props.className || ""]: props.className,
    });
    return (
        <Button onClick={props.onClick} className={BigRoundedButtonClassName}>{props.children}</Button>
    )
};
