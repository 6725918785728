import {useEffect, useRef, useState} from "react";

// the difference between this component and normal __dangerouslySetHtml is that this executes any scripts inside
export default function DangerouslySetHtmlContent(props: {
    html: string,
    [key: string]: any,
}): JSX.Element {
    const { html, ...rest } = props;
    const divRef = useRef<HTMLDivElement>(null);
    const [useDangerouslySetInnerHtml, setUseDangerouslySetInnerHtml] = useState<boolean>(false);

    useEffect(() => {
        if (!html || !divRef.current) {
            return;
        }

        try {
            const n: Node = document.createRange().createContextualFragment(html) // Create a 'tiny' document and parse the html string
            divRef.current.innerHTML = '' // Clear the container
            divRef.current.appendChild(n); // Append the new content
        } catch (e) {
            // This error happens when createContextualFragment() isn't supported in certain browsers
            if (e instanceof DOMException && e.code === 9) {
                console.debug('DOMException occurred, falling back to use dangerouslySetInnerHTML');
                setUseDangerouslySetInnerHtml(true);
            } else {
                throw e;  // re-throw the error unchanged
            }
        }
    }, [html])

    return (
        <div {...rest} ref={divRef}
             dangerouslySetInnerHTML={useDangerouslySetInnerHtml ? {__html: html} : undefined} />
    )
}
