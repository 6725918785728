import {useContext, useEffect, useRef, useState} from "react";
import {StoreContext} from "../stores/StoreLoader";
import {getQueries} from "../utils/SchoolBlocksUtilities";
import SafeRouter from "../components/utilities/SafeRouter";
import useAsyncEffect from "./useAsyncEffect";
import NotificationManager from "../components/notifications/NotificationManager";

export function useShowFollowingNotificationOnLogin() {
    const {interfaceStore, organizationStore} = useContext(StoreContext);

    useAsyncEffect(async () => {
        const queries = getQueries(interfaceStore.currentFullUrl)
        if (queries.newfollower) {
            await SafeRouter.setQuery("newfollower", undefined)
            NotificationManager.success(`You have been automatically subscribed to 
            ${organizationStore.organization.title} after logging in.`, undefined, 5000)
        }

    }, [interfaceStore.currentFullUrl]);
}
