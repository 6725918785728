import { useContext } from "react";
import {observer} from "mobx-react";

import classNames from "classnames";
import styles from "./styles/FooterCS.module.scss";

import AllModals from "../modals/_AllModals";
import PoweredBy from './PoweredBy';
import FooterAddressColumn from "./FooterAddressColumn";
import FooterAccountColumn from './FooterAccountColumn';
import FooterNavColumn from './FooterNavColumn';
import FooterSocialColumn from './FooterSocialColumn';
import {StoreContext} from "../../stores/StoreLoader";
import FooterHTML from "./FooterHTML";
import DangerouslySetHtmlContent from "../utilities/DangerouslySetHtmlContent";
import {ErrorBoundary} from "../utilities/ErrorBoundary";
import dynamic from "next/dynamic";

// Markdown introduces a bunch of conversion utilities that are fairly heavy. Don't pre-render it on the server
// so we have s lightly faster TTFP.
const Markdown = dynamic(() => import("../utilities/Markdown"), {ssr: false});

const mainFooterClassName = classNames({
    [styles.mainFooter]: true,
});
const descriptionColumnClassName = classNames({
    [styles.descriptionColumn]: true,
});

const Footer = observer(props => {
    const {organizationStore, userStore, interfaceStore} = useContext(StoreContext);

    let accountColumn;
    if (userStore.id || userStore.isAdmin) {
        accountColumn = <FooterAccountColumn/>;
    } else {
        const text = organizationStore.organization.json_data.settings?.headerText;

        if (text) {
            accountColumn = <div className={descriptionColumnClassName}>
                <Markdown source={text}/>
            </div>;
        }
    }

    let supportCode;
    if (organizationStore.organization.json_data.settings.support && !(
        organizationStore.organization.json_data.settings.support.authenticatedOnly && userStore.id
    )) {
        supportCode = <ErrorBoundary userGenerated={true}>
            <DangerouslySetHtmlContent html={organizationStore.organization.json_data.settings.support.code}/>
        </ErrorBoundary>
    }

    return (<>
        <footer className={mainFooterClassName}>
            <div className={styles.navContainer}>
                <FooterAddressColumn/>
                {accountColumn}
                <FooterNavColumn/>
                <FooterSocialColumn/>
            </div>
            <FooterHTML/>
            <PoweredBy/>
        </footer>
        <AllModals />
        {supportCode}
    </>
    );
});

export default Footer;
