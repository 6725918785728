import {useContext} from "react";
import {StoreContext} from "../../../stores/StoreLoader";
import styles from "../styles/HeaderShortcuts.module.scss";
import FontAwesome from "../../utilities/FontAwesome";
import classNames from "classnames";
import NavigationLink from "../navigation/NavigationLink";

type EditableProps = {
    canEdit: true;
    deleteNavigation: (id: number) => void;
    updateNavigation: (navigation: Navigation) => void;
};

type NonEditableProps = {
    canEdit: false;
};

export function Shortcut(props: {
    shortcut: Navigation,
    className?: string,
} & (EditableProps | NonEditableProps)) {
    const {organizationStore} = useContext(StoreContext);

    const popupClassName = classNames({
        [styles.popup]: true,
        [styles.popupTitle]: props.canEdit && (props.shortcut.parent === organizationStore.currentOrganization.id),
    });

    return (
        <li className={props.className}>
            <div className={styles.popup}>
                {props.canEdit && <div>
                    <button style={{marginRight: '10px'}} title={"Edit Link"}
                            onClick={() => props.updateNavigation(props.shortcut)}>
                        <FontAwesome ariaHidden name={'fa-pen'} prefix={'fas'}/>
                    </button>
                    <button title={"Delete Link"} onClick={() => props.deleteNavigation(props.shortcut.id)}>
                        <FontAwesome ariaHidden name={'fa-trash'} prefix={'fas'}/>
                    </button>
                </div>}
            </div>
            <NavigationLink href={props.shortcut.url} navigationObj={props.shortcut} aria-label={props.shortcut.title}>
                <FontAwesome fixedWidth name={props.shortcut.icon} ariaHidden={true}/>
                <div className={popupClassName}>
                    <div>
                        {props.shortcut.title}
                    </div>
                </div>
            </NavigationLink>
        </li>
    )
}
