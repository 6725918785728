import PropTypes from "prop-types";
import {observer} from "mobx-react";

import styles from "./styles/NavigationPrimary.module.scss";
import classNames from "classnames";
import SchoolBlocksHeader from "./SchoolBlocksHeader";
import * as React from "react";
import {useMainUrl} from "../../hooks/useMainUrl";

const NavigationPrimary = observer(props => {
    const mainUrl = useMainUrl();

    const containerClassName = classNames({
        [styles.schoolBlocksHeader]: true,
        [styles.schoolBlocksHeaderWelcomePage]: !props.isWelcomePage,
        [styles.schoolBlocksHeaderHeroIsEnabled]: props.heroIsEnabled,
        [styles.schoolBlocksHeaderVisible]: props.visible,
    });

    return (
        <div className={containerClassName}>
            <SchoolBlocksHeader mainUrl={mainUrl} hideNavigation={true} hideLoginButton={false} preventTabbing={props.isWelcomePage || !props.visible}/>
        </div>
    );
});

NavigationPrimary.propTypes = {
    isWelcomePage: PropTypes.bool,
    heroIsEnabled: PropTypes.bool,
    visible: PropTypes.bool,
};

export default NavigationPrimary;
