import classNames from "classnames";
import styles from "./styles/FooterCS.module.scss";
import {observer} from "mobx-react";
import ClickableLink from "../utilities/ClickableLink";
import FontAwesome from "../utilities/FontAwesome";
import { useTranslation } from 'react-i18next';
import {useUserMenuLinks} from "../header/UserDropDownMenu";

const adminColumnClassName = classNames({
    [styles.adminColumn]: true,
});

const FooterAccountColumn = observer(() => {
    const { t } = useTranslation('translation');

    const schoolBlocksAdminLinks = useUserMenuLinks();

    let links = schoolBlocksAdminLinks.map((l, i) => <li key={i}>
        <ClickableLink title={l.title} href={l.path}>
            <FontAwesome ariaHidden={true} name={l.icon} /> {l.title}
        </ClickableLink>
    </li>);

    return (
        <div className={adminColumnClassName}>
            <h3>{t('ACCOUNT')}</h3>
            <ul>
                {links}
            </ul>
        </div>
    )
});

export default FooterAccountColumn;
