import styles from './Microsite.module.scss';
import {useCallback, useContext, useEffect, useState} from "react";
import {StoreContext} from "../../../stores/StoreLoader";
import FontAwesome from "../../utilities/FontAwesome";
import classNames from 'classnames';
import {convertFlatToTree} from "../../../utils/DataUtilities";
import {IBreadcrumb} from "../../../pages/MainGridPage";
import {runInAction, toJS} from "mobx";
import {observer} from "mobx-react";
import NotificationManager from "../../notifications/NotificationManager";
import {MicrositeNavigation} from "./MicrositeNavigation";
import {OrganizationTypesWithSubNavs} from "../../../types/OrganizationTypeValues";
import dynamic from "next/dynamic";

const EditorMicrositeNavigation = dynamic(() => import("./EditorMicrositeNavigation"));

type IMicrositeProps = {
    micrositeOpen: boolean,
    setMicrositeOpen: React.SetStateAction<any>,
}

export interface IMicrositeLink extends IBreadcrumb {
    path: string,
    id: string,
    children: IMicrositeLink[],
}

function getMicrositeEnabled(microsite) {
    if (!microsite) return false;
    const settings = microsite.json_data?.settings;
    if (settings && typeof settings.micrositeEnabled === "boolean") {
        return settings.micrositeEnabled;
    }
    return microsite.navigation.length > 2;
}

type UseMicrositeReturnValue = {
    canShowMicrosite: boolean,
    toggleShouldShowMicrosite: () => void,
    subNavigation: IMicrositeLink[],
} & ({
    shouldShowMicrosite: true,
    microsite: BaseOrganization,
} | {
    shouldShowMicrosite: false,
    microsite: undefined,
})

export const useMicrosite = (): UseMicrositeReturnValue => {
    const {organizationStore} = useContext(StoreContext);

    const microsite = organizationStore.currentOrganization.microsite;
    const micrositeEnabled: boolean = getMicrositeEnabled(microsite);

    const toggleShouldShowMicrosite = useCallback(async () => {
        try {
            const result = await organizationStore.fetchClient("organizations", "organizations_partial_update", {
                "id": organizationStore.currentOrganization.id,
                data: {micrositeEnabled: !micrositeEnabled},
                expand: ["microsite"],
            });
            runInAction(() => {
                organizationStore.currentOrganization.json_data = result.obj.json_data;
                organizationStore.currentOrganization.microsite = result.obj.microsite;
            });
            NotificationManager.success(`Microsite successfully ${micrositeEnabled ? "disabled" : "enabled"}!`);
        } catch (e) {
            NotificationManager.error("Something went wrong. Please try again.")
        }
    }, [micrositeEnabled]);

    let subNavigation: IMicrositeLink[] = [];
    if (microsite) {
        subNavigation = convertFlatToTree(microsite.navigation.slice(1));
    }

    let shouldShowMicrosite = !!microsite && micrositeEnabled;

    return {
        microsite,
        canShowMicrosite: OrganizationTypesWithSubNavs.includes(organizationStore.currentOrganization.type) &&
            organizationStore.currentOrganization.parent_id === organizationStore.organization.id,
        shouldShowMicrosite,
        toggleShouldShowMicrosite,
        subNavigation,
    }
}

export const Microsite = observer((props: IMicrositeProps) => {
    const {organizationStore, userStore, modalStore} = useContext(StoreContext);

    const [reorder, setReorder] = useState(false);
    const [incomingFocusElement, setIncomingFocusElement] = useState<Element | null>(null);

    const isTopLevelMicrositePage = organizationStore.currentOrganization.microsite.navigation[0]?.model.id === organizationStore.currentOrganization.id;

    const closeButtonContainerClassName = classNames({
        [styles.closeButtonContainer]: true,
        [styles.closeButtonContainerIsOpen]: props.micrositeOpen,
    })
    const reorderButtonClassName = classNames({
        [styles.reorderButton]: true,
        [styles.reorderButtonEnabled]: reorder,
    })

    const {subNavigation} = useMicrosite();

    const focusCloseButton = () => {
        const closeButton = document.getElementById('closeButton') as HTMLButtonElement;
        if (closeButton) closeButton.focus();
    }

    const handleKeydown = (evt) => {
        if (evt.code === "Escape") {
            props.setMicrositeOpen(false)
        }
    }

    useEffect(() => {
        setTimeout(()=>{
            if (props.micrositeOpen) {
                setIncomingFocusElement(document.activeElement);
                focusCloseButton();
            } else if (!props.micrositeOpen && incomingFocusElement) (incomingFocusElement as HTMLLinkElement).focus();

            window.addEventListener('keydown', handleKeydown);
            return () => {
                window.removeEventListener('keydown', handleKeydown);
            }
        }, 250)
    }, [props.micrositeOpen]);

    return <div className={styles.container}>
        <div className={closeButtonContainerClassName}>
            <button id={'closeButton'} aria-label={"Close Navigation"} onClick={() => props.setMicrositeOpen(false)}>
                <FontAwesome name={"fa-times"} prefix={"fas"} size={"2x"}/>
            </button>
        </div>
        {subNavigation.length > 0 && <ul className={styles.linksContainer}>
            {subNavigation.map((link, index) => {
                if (userStore.editor) {
                    return <EditorMicrositeNavigation
                        key={link.id}
                        link={link}
                        index={index}
                        reorder={reorder}
                        micrositeOpen={props.micrositeOpen}
                        setMicrositeOpen={props.setMicrositeOpen}
                    />
                } else {
                    return <MicrositeNavigation
                        key={link.id}
                        link={link}
                        index={index}
                        micrositeOpen={props.micrositeOpen}
                        setMicrositeOpen={props.setMicrositeOpen}
                    />
                }
            })}
        </ul>}
        {userStore.editor && isTopLevelMicrositePage && <div>
            <button onClick={() => {
                modalStore.addModal({
                    id: "addOrEditBlock",
                    type: 'addOrEditBlock',
                    intent: "add",
                    blockType: "section",
                })
            }
            }>Create Section
            </button>
            <button className={reorderButtonClassName} onClick={() => setReorder(v => !v)}>
                Reorder Sections
            </button>
        </div>}
        <span tabIndex={0}
              onFocus={focusCloseButton}
        />
    </div>
})
