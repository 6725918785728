const commonARAR = require('./locales/ar-AR/common.json');
const commonENUS = require('./locales/en-US/common.json');
const commonESXL = require('./locales/es-XL/common.json');
const commonFRFR = require('./locales/fr-FR/common.json');
const commonPTPT = require('./locales/pt-PT/common.json');
const commonRWRW = require('./locales/rw-RW/common.json');
const commonSOSO = require('./locales/so-SO/common.json');
const commonSWKE = require('./locales/sw-KE/common.json');
const commonZHCN = require('./locales/zh-CN/common.json');

const resources = {
    // Keep Alphabetical.
    "ar-AR": {
        translation: commonARAR,
    },
    "en-US": {
        translation: commonENUS,
    },
    "es-XL": {
        translation: commonESXL,
    },
    "fr-FR": {
        translation: commonFRFR,
    },
    "pt-PT": {
        translation: commonPTPT,
    },
    "rw-RW": {
        translation: commonRWRW,
    },
    "so-SO": {
        translation: commonSOSO,
    },
    "sw-KE": {
        translation: commonSWKE,
    },
    "zh-CN": {
        translation: commonZHCN,
    },
};

export default resources
