import React, {useEffect, useState} from 'react';
import NotificationManager from './NotificationManager';
import Notifications from './Notifications';
import {reaction, values} from "mobx";
import {observer} from "mobx-react";

export const NotificationContainer = observer((props: {
    enterTimeout?: number,
    leaveTimeout?: number,
}) => {
    const {enterTimeout = 400, leaveTimeout = 400} = props;
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const disabler = reaction(() => values(NotificationManager.listNotify), handleStoreChange);
        return () => disabler();
    }, []);

    function handleStoreChange(notifications) {
        setNotifications(notifications);
    }

    function handleRequestHide(notification) {
        NotificationManager.remove(notification);
    }

    return (
        <Notifications
            enterTimeout={enterTimeout}
            leaveTimeout={leaveTimeout}
            notifications={notifications}
            onRequestHide={handleRequestHide}
        />
    );
})
