import {useContext, useMemo} from "react";
import {getQueries} from "../utils/SchoolBlocksUtilities";
import {StoreContext} from "../stores/StoreLoader";
import {GridTypes} from "../pages/MainGridPage";

export const useGridType = (options: {useTitleOrg?: boolean} = {}): "terraced" | "packery" => {
    const {interfaceStore, organizationStore} = useContext(StoreContext);
    const {useTitleOrg = false} = options;

    const organization = useTitleOrg ? organizationStore.organization : organizationStore.currentOrganization;

    return useMemo(() => {
        const queries = getQueries(interfaceStore.currentFullUrl || "");
        if (queries.gridType && GridTypes[queries.gridType] &&
            (useTitleOrg ? organizationStore.organization.id === organizationStore.currentOrganization.id : true)) {
            return GridTypes[queries.gridType]
        }
        return !!organization.json_data?.terracedGrid?.enabled ? GridTypes.terraced : GridTypes.packery;
    }, [interfaceStore.currentFullUrl, organization?.json_data?.terracedGrid?.enabled])
}