import {forwardRef, useContext, useEffect, useState} from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import styles from "./styles/SidebarContainer.module.scss";
import {Transition} from 'react-transition-group';
import FontAwesome from "../../utilities/FontAwesome";
import {StoreContext} from "../../../stores/StoreLoader";
import ModalBackdrop from "../../modals/_ModalBackdrop";
import zIndex from "../../../styles/_zIndex.module.scss";
import {runInAction} from "mobx";
import modalStyles from "../../modals/styles/Modal.module.scss";

const defaultStyle = {
    transition: `transform 250ms ease`,
    transform: 'translateX(-100%)',
};

const transitionStyles = {
    entering: {transform: 'translateX(0)'},
    entered: {transform: 'translateX(0)'},
    exiting: {transform: 'translateX(-100%)'},
    exited: {transform: 'translateX(-100%)'},
};

export const sidebarKey = "sidebar";

const SidebarContainer = observer(forwardRef((props, ref) => {
    const {sidebarStore, modalStore} = useContext(StoreContext);

    const [backdropZIndex, setBackdropZIndex] = useState(0);

    const containerClassName = classNames({
        [styles.container]: true,
        [props.className]: props.className,
    });

    const onMouseDown = () => {
        sidebarStore.close();
    }

    useEffect(() => {
        if (props.in) {
            modalStore.addModal({type: sidebarKey});
            const index = modalStore.stack.findIndex(m => m.type === sidebarKey);
            setBackdropZIndex(Number(zIndex["z-index-modal"]) + index * 10);
        } else {
            runInAction(() => {
                modalStore.stack = modalStore.stack.filter(m => m.type !== sidebarKey);
            });
            if (modalStore.stack.length === 0) {
                document.body.classList.remove(modalStyles.modalOpen);
            }
        }
    }, [props.in]);

    return (
        <Transition in={props.in} timeout={500} mountOnEnter>
            {(state) => {
                return (
                    <div style={{pointerEvents: "auto"}}>
                        <section id={"sb-admin-side-panel"} ref={ref} className={containerClassName}
                                 style={{...defaultStyle, ...transitionStyles[state], zIndex: backdropZIndex + 1}}>
                            <button className={styles.topExitButton} onClick={sidebarStore.close} aria-label={"Exit"}>
                                <FontAwesome prefix={'fas'} name={'fa-times'}/>
                            </button>
                            {props.children}
                        </section>
                        {(state === "entered" || state === "entering") && <ModalBackdrop
                            active={true}
                            onMouseDown={onMouseDown}
                            zIndex={backdropZIndex}
                        />}
                    </div>
                )
            }}
        </Transition>
    );
}));

export default SidebarContainer;
