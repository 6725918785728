import {isExternalLink, isMissingProtocol} from "../../../utils/SchoolBlocksUtilities";
import styles from "./meganav/styles/dropdown.module.scss";
import FontAwesome from "../../utilities/FontAwesome";
import React, {PropsWithChildren, useContext} from "react";
import {StoreContext} from "../../../stores/StoreLoader";
import {observer} from "mobx-react";

const TitleWithPopoutIcon: React.FC<PropsWithChildren<{
    url: string,
}>> = observer((props) => {
    const {url} = props;
    const {interfaceStore} = useContext(StoreContext);
    let href= url
    if (isMissingProtocol(href)) {
        // this should look like an absolute URL, but it doesn't.  Example: "www.url.com"
        href = "http://" + href;
    }
    return (<div className={styles.dropDownDetails}>
        {props.children}
        {href && isExternalLink(href, interfaceStore.currentFullUrl) && <span className={styles.popOutIcon}>
            <FontAwesome style={{marginLeft: '5px', marginRight: '10px'}} prefix={'fas'} name="fa-external-link-alt"/>
        </span>}
    </div>
    )
});

export default TitleWithPopoutIcon;
