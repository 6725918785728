import { Component } from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

/*
BIG NOTE: make sure the classNames in flaticon.css say 'flaticon_flaticon-'!!!!
*/
// import "./styles/FlatIcons.module.scss"

export default class FlatIcon extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        ariaHidden: PropTypes.bool,
        onClick: PropTypes.func,
        title: PropTypes.string,
        style: PropTypes.object,
    };

    static defaultProps = {
        onClick: () => {},
        style: {},
    };

    render() {
        const iconClassName = classNames({
            [this.props.name]: true,
            [this.props.className]: this.props.className,
        });

        return (
            <span
                onClick={this.props.onClick}
                aria-hidden={this.props.ariaHidden}
                className={iconClassName}
                title={this.props.title}
                style={this.props.style}
            />
        )
    }
}
