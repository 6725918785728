import { useContext } from 'react';
import * as React from 'react';
import {StoreContext} from "../../stores/StoreLoader";
import styles from './styles/FooterCS.module.scss';
import Editable from "../utilities/SimplePopupEditor";
import {observer} from "mobx-react";
import {ErrorBoundary} from "../utilities/ErrorBoundary";

const FooterHTML: React.FC<{}> = observer(() => {
    const {organizationStore, userStore} = useContext(StoreContext);

    let footerText = organizationStore?.organization?.json_data?.settings?.footerText || "";
    if (userStore.isAdmin && !footerText) {
        footerText = "Insert Disclaimer or Footer Text Here";
    }

    function saveNewText(text) { // this will need to update database, then set autorun to listen to updates to title in mobx store
        organizationStore.updateSettings({
            "footerText": text,
        });
    }

    return <div className={styles.footerText}>
        <Editable initialValue={footerText} handleSave={saveNewText} editable={userStore.isAdmin}>
            <ErrorBoundary userGenerated={true}>
                <span dangerouslySetInnerHTML={{__html: footerText}}/>
            </ErrorBoundary>
        </Editable>
    </div>

});

export default FooterHTML;
