import {YiiUrlFactory} from "../backends/YiiRoutes";
import {useContext} from "react";
import {StoreContext} from "../stores/StoreLoader";

export function useMainUrl() {
    const {organizationStore, i18nStore} = useContext(StoreContext);

    return YiiUrlFactory(
        organizationStore.organization.json_data?.settings?.appearance?.showWelcomePage ? "/?showHomepage=true" : "/",
        i18nStore.currentOrgSupportedLocale
    );
}