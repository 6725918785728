import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FontAwesome from "../../../utilities/FontAwesome";
import styles from "./styles/megaNav.module.scss";
import NavigationLink from "../NavigationLink";

export default
class MainLink extends Component {
    static propTypes = {
        navigationObj: PropTypes.shape({
            title: PropTypes.string.isRequired,
            url: PropTypes.string,
            type: PropTypes.number,
        }).isRequired,
        events: PropTypes.object,
    };

    static defaultProps = {
        hasDropdown: true,
    };

    constructor(props) {
        super(props);

        this.domElement = null;
    }

    getDomElement() {
        return this.domElement;
    }

    render() {
        const { events, hasPopup, expanded } = this.props;
        const { title, icon, url } = this.props.navigationObj;

        const mainLinkClassName = classNames({
            [styles.mainLink]: true,
        });

        return (
            <li role={"none"} className={mainLinkClassName} {...events} ref={(r) => this.domElement = r}>
                <NavigationLink className={"mainLink"} navigationObj={this.props.navigationObj} role={"menuitem"} aria-label={title} aria-expanded={expanded} aria-haspopup={hasPopup}>
                    {icon && <FontAwesome className={styles.icon} ariaHidden={true} name={icon}/>}
                    <span>{title}</span>
                    {hasPopup && <FontAwesome className={styles.popupArrow} name={'fa-angle-right'} prefix={'fas'} style={{transform: expanded ? "rotate(90deg)" : "rotate(0)"}} />}
                </NavigationLink>
            </li>
        );
    }
}
