import {MutableRefObject, useEffect, useRef} from 'react';

const useClickOutside = (onClickOutside: (e: Event) => void): MutableRefObject<HTMLDivElement | null> => {
    const ref = useRef<HTMLDivElement | null>(null);

    const handleClick = (e) => {
        const preserveEvent = e;
        if (ref.current && !ref.current.contains(preserveEvent.target)) {
            onClickOutside(preserveEvent);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => document.removeEventListener('mousedown', handleClick);
    });

    return ref;
};

export default useClickOutside;
