import * as React from "react";
import {useContext, useState, useRef, useEffect} from "react";
import {StoreContext} from "../../stores/StoreLoader";
import {IBreadcrumb} from "../../pages/MainGridPage";
import styles from "./styles/TerraceSubPageHeader.module.scss"
import ClickableLink from "../utilities/ClickableLink";
import FontAwesome from "../utilities/FontAwesome";
import {EditableTitle} from "./Breadcrumbs";
import FollowPageButton from "./FollowPageButton";
import {IMicrositeLink} from "../header/microsite/Microsite";
import classNames from 'classnames';
import {observer} from "mobx-react";
import dynamic from "next/dynamic";
import {useToggleFollowPage} from "../../hooks/useToggleFollowPage";

const DraggableSubPageNavigation = dynamic(() => import("./TerraceSubPageNavigation"));

const TerraceSubPageHeader: React.FC<{
    breadcrumbs: IBreadcrumb[],
    editable?: boolean,
}> = observer(props => {
    const {organizationStore, modalStore, userStore, interfaceStore} = useContext(StoreContext);
    const [reorderingEnabled, setReorderingEnabled] = useState(false);
    const [hideScrollLeft, setHideScrollLeft] = useState(true);
    const scrollContainerRef = useRef<null | HTMLDivElement>(null);
    let {breadcrumbs} = props;

    // Need to use IntersectionObserver to determine the first visible index in the microsite scrollable container.
    // We are allowing the user to either scroll or use the arrow keys to navigate the microsites. There could be
    // a mismatch between the current arrow key index and the actual index shown on screen if the user scrolls, then
    // uses the arrow keys.
    const [firstVisibleIndex, setFirstVisibleIndex] = useState<number>(0);
    const [lastVisibleIndex, setLastVisibleIndex] = useState<number>(0);

    const scrollRight = () => {
        const element = document.getElementById(`microsite-${firstVisibleIndex}`);
        if (element) {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollBy({
                    left: element.clientWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    const scrollLeft = () => {
        const element = document.getElementById(`microsite-${firstVisibleIndex-1}`);
        if (element) {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollBy({
                    left: -element.clientWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    const visibleIndices = useRef(new Set([0])); // Using useRef to track visible indices without causing re-renders

    useEffect(() => {
        const updateVisibility = (entries) => {
            entries.forEach(entry => {
                const index = parseInt(entry.target.getAttribute('data-index'), 10);

                if (entry.isIntersecting && entry.intersectionRatio >= 0.6) {
                    visibleIndices.current.add(index);
                } else {
                    visibleIndices.current.delete(index);
                }

                if (index === 0) {
                    setHideScrollLeft(entry.isIntersecting && entry.intersectionRatio >= 0.6);
                }
            });

            setFirstVisibleIndex(Math.min(...visibleIndices.current));
            setLastVisibleIndex(Math.max(...visibleIndices.current));
        };

        const observer = new IntersectionObserver(updateVisibility, {
            root: scrollContainerRef.current,
            threshold: 0.6,
        });

        const items = scrollContainerRef.current?.querySelectorAll('.scroll-item');
        items?.forEach(item => observer.observe(item));

        return () => {
            items?.forEach(item => observer.unobserve(item));
            observer.disconnect();
        };
    }, [interfaceStore.currentFullUrl]);

    if (interfaceStore.breakpoint === "break-point-xs") {
        if (breadcrumbs.length > 1) {
            breadcrumbs = [breadcrumbs[0], breadcrumbs[breadcrumbs.length-1]]
        }
        else {
            breadcrumbs = [breadcrumbs[0]]
        }
    }

    const children: IMicrositeLink[] = organizationStore.currentOrganization.sub_navigations
    const hideScrollRight = userStore.isEditor ? lastVisibleIndex === children.length + 1 : lastVisibleIndex === children.length + 1

    const micrositeContainerClassName = classNames({
        [styles.micrositeContainer]: true,
    })

    const micrositeContainerOverlayRightClassName = classNames({
        [styles.micrositeContainerOverlayRight]: true,
        [styles.micrositeContainerOverlayRightWithArrows]: (!hideScrollRight && hideScrollLeft) ||
        (!hideScrollRight && !hideScrollLeft) || (hideScrollRight && !hideScrollLeft),
    })

    const scrollLeftClassName = classNames({
        [styles.scrollLeft]: true,
        [styles.scrollLeftHidden]: hideScrollLeft,
    })

    const scrollRightClassName = classNames({
        [styles.scrollRight]: true,
        [styles.scrollRightHidden]: hideScrollRight,
    })

    const toggleFollowing = useToggleFollowPage()

    const lastBreadcrumbIndex = breadcrumbs.length - 1;

    return (
        <div className={styles.container}>
            <div>
                {breadcrumbs.map((breadcrumb, index) => {
                    const itemKey = `${organizationStore.currentOrganization.id}-${breadcrumb?.model?.id || breadcrumb.url}`;
                    const breadcrumbsClassName = classNames({
                        ['sb-organization-color-element-font']: true,
                        [styles.breadcrumbSelected]: organizationStore.currentOrganization.id === breadcrumb.model?.id,
                    })
                    return (
                        <span key={itemKey} className={styles.title}>
                            {organizationStore.currentOrganization.id ===
                        breadcrumb.model?.id ? (
                            <div className={breadcrumbsClassName}>
                                <EditableTitle
                              breadcrumb={breadcrumb}
                              editable={props.editable}
                            />
                            </div>
                        ) : (
                            <ClickableLink
                            href={breadcrumb.url}
                            className={breadcrumbsClassName}
                          >
                                {interfaceStore.breakpoint === "break-point-xs" &&
                            organizationStore.organization.id !==
                              organizationStore.currentOrganization.id ? (
                                  <FontAwesome name={"fa-home"} prefix={"fas"} />
                            ) : (
                                <>{breadcrumb.label}</>
                            )}
                            </ClickableLink>
                        )}

                            {index !== lastBreadcrumbIndex && (
                                <FontAwesome
                            name={"fa-chevron-right"}
                            prefix={"fas"}
                          />
                        )}

                        </span>
                    );
                })}
                { children.length === 0 && userStore.editor &&
                    <div className={styles.addSubPageInitial}>
                        <button
                            key={children.length + 1}
                            data-index={children.length + 1}
                            className="scroll-item"
                            id={`microsite-${children.length + 1}`}
                            title={"Add New Sub-section"}
                            onClick={() => {
                                modalStore.addModal({
                                    id: "addOrEditBlock",
                                    type: "addOrEditBlock",
                                    intent: "add",
                                    blockType: "section",
                                });
                            }}
                        >
                            <FontAwesome prefix={"fa-solid"} name={"fa-plus"} /> <span>Add Subpage</span>
                        </button>
                    </div>
                }
                <FollowPageButton follower={userStore.follower}
                                  toggleFollowing={toggleFollowing}
                                  symbolOnly={true}
                                  className={styles.followButton}
                                  size={'2x'}
                                  organizationTitle={organizationStore.currentOrganization.title}
                />
                &nbsp;
            </div>
            {
                        (children.length > 0) &&
                            <div>
                                <button aria-label={"Next Section"} className={scrollRightClassName} onClick={scrollRight}><FontAwesome
                                name="fa-chevron-right" prefix={'fas'} fixedWidth title="Next"/></button>
                                <button aria-label={"Previous Section"} className={scrollLeftClassName} onClick={scrollLeft}><FontAwesome
                        name="fa-chevron-left" prefix={'fas'} fixedWidth title="Previous"/></button>
                                <div style={{
                                    marginInlineStart: hideScrollLeft ? 0 : 25,
                                    marginInlineEnd: hideScrollRight ? 0 : 25,
                                }} className={micrositeContainerClassName} ref={scrollContainerRef}>
                                    {children.map((child, idx) => {
                            if (userStore.editor) {
                                return <div key={idx} data-index={idx} className="scroll-item" id={`microsite-${idx}`} style={{display: 'inline-block'}}>
                                    <DraggableSubPageNavigation reorder={reorderingEnabled} link={child} index={idx}/>
                                </div>
                            } else {
                                return <ClickableLink key={idx} data-index={idx} className={`scroll-item ${styles.microsite}`} id={`microsite-${idx}`} href={child.url}>
                                    {child.label}
                                </ClickableLink>
                            }
                        })}
                                    {userStore.editor && <>
                                        {children.length > 1 &&
                                            <button key={children.length} data-index={children.length} className="scroll-item" id={`microsite-${children.length}`} title={"Reorder Sub-sections"} onClick={() => setReorderingEnabled(v => !v)}>
                                                <FontAwesome prefix={'fa-solid'} name={'fa-arrows-left-right'} style={{
                                        borderRadius: 5,
                                        border: `3px solid ${reorderingEnabled ? "#545454" : "transparent"}`,
                                                }}/>
                                            </button>}
                                        <button key={children.length + 1} data-index={children.length + 1} className="scroll-item" id={`microsite-${children.length + 1}`} title={"Add New Sub-section"} onClick={() => {
                                modalStore.addModal({
                                    id: "addOrEditBlock",
                                    type: 'addOrEditBlock',
                                    intent: "add",
                                    blockType: "section",
                                })
                                        }}>
                                            <FontAwesome prefix={'fa-solid'} name={'fa-plus'}/>
                                        </button>
                                    </>}
                                </div>
                                <div className={micrositeContainerOverlayRightClassName}></div>
                            </div>
            }
        </div>
    )
})

export default TerraceSubPageHeader;
