import classNames from "classnames";
import {observer} from "mobx-react";
import {useContext} from "react";
import dynamic from "next/dynamic";

import Button from "../utilities/Button";
import ClickableLink from "../utilities/ClickableLink";
import FlatIcon from "../utilities/FlatIcon";
import FontAwesome from "../utilities/FontAwesome";
import {StoreContext} from "../../stores/StoreLoader";
import {isSchoolBlocksApp} from "../../utils/SchoolBlocksUtilities";

const PhoneNumber = dynamic(() => import("../utilities/PhoneNumber"));

import styles from "./styles/FooterCS.module.scss";

const addressColumnClassName = classNames({
    [styles.addressColumn]: true,
});

const FooterAddressColumn = observer(props => {
    const {organizationStore, modalStore, userStore, interfaceStore} = useContext(StoreContext);

    const location = organizationStore.organization.locations?.[0];

    let locationBlock = null;
    if (location) {
        locationBlock =
            <address aria-label={`Address for ${organizationStore.organization.title}`} className={"notranslate"}>
                {location.address && <p>{location.address}</p>}
                {location.address2 && <p>{location.address2}</p>}
                <p>{location.city ? `${location.city}, ` : ""}{location.state ? `${location.state} ` : ""}{location.zip ? location.zip : ""}</p>
            </address>
    }

    const toggleInlineEditing = () => {
        modalStore.addModal({
            type: "edit-location",
            key: "edit-location",
            id: "edit-location",
            handleUnmount: () => {
            },
        })
    };

    return (
        <div className={addressColumnClassName}>
            <h3 className={"notranslate"}>{organizationStore.organization.title}</h3>
            {userStore.isAdmin &&  !isSchoolBlocksApp() &&
            <Button id={"editAddress"} onClick={toggleInlineEditing}><FlatIcon ariaHidden={true} name={"flaticon-cogwheel"}/> Edit Address</Button>}
            {locationBlock && <div>
                <div>
                    <FlatIcon ariaHidden={true} name={"flaticon-map"}/>
                </div>
                <div>
                    {locationBlock}
                </div>
            </div>}
            {locationBlock && location.phone && <div>
                <div>
                    <FlatIcon title="Primary Phone Number" ariaHidden={true} name={"flaticon-telephone-of-old-design"}/>
                </div>
                <div>
                    <PhoneNumber ariaLabel={`Call primary phone number ${location.phone}`} isLinked number={location.phone} description={location.phone_description}/>
                </div>
            </div>}
            {locationBlock && location.phone2 && <div>
                <div>
                    <FlatIcon title="Secondary Phone Number" ariaHidden={true} name={"flaticon-telephone-of-old-design"}/>
                </div>
                <div>
                    <PhoneNumber ariaLabel={`Call secondary phone number ${location.phone2}`} isLinked number={location.phone2} description={location.phone2_description}/>
                </div>
            </div>}
            {locationBlock && location.fax && <div>
                <div>
                    <FontAwesome title="Fax" ariaHidden={true} name={"fa-print"} prefix={"fas"}/>
                </div>
                <div>
                    <PhoneNumber ariaLabel={`Fax ${location.fax}`} number={location.fax} description={location.fax_description}/>
                </div>
            </div>}
            {locationBlock && location.email && <div>
                <div>
                    <FontAwesome title="Email" ariaHidden={true} name={"fa-at"} prefix={"fas"}/>
                </div>
                <div>
                    <ClickableLink title="Email" href={`mailto://${location.email}`}>{location.email}</ClickableLink>
                </div>
            </div>}
        </div>
    )
});

export default FooterAddressColumn;
