import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {i18nStore} from "../stores/StoreLoader";
import resources from "./resources"

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: i18nStore.currentOrgSupportedLocale,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safe from xss
        },
    });

export default i18n;
