import React, {useContext, useEffect, useState, ReactElement} from 'react';
import FontAwesome, {FontAwesomeSize} from "../utilities/FontAwesome";
import styles from "../../pages/styles/MainGridPage.module.scss";
import followStyles from "./styles/FollowFeedPageItem.module.scss"
import {StoreContext} from "../../stores/StoreLoader";

function FollowPageButton(props: {
    symbolOnly?: boolean,
    follower: boolean,
    toggleFollowing: () => void,
    className?: string,
    smallIcon?: boolean,
    size?: FontAwesomeSize,
    organizationTitle: string,
}) {
    const {userStore, modalStore} = useContext(StoreContext);
    const [followIcon, setFollowIcon] = useState<ReactElement | null>(null);
    const [followText, setFollowText] = useState<ReactElement | null>(null);

    useEffect(() => {
        if (props.symbolOnly) {
            if (props.follower) {
                setFollowIcon(<FontAwesome prefix={'fas'} name={'fa-star'} size={props.smallIcon ? undefined : props.size}/>)
            }
            else {
                setFollowIcon(<FontAwesome name={'fa-star'} prefix={'far'} size={props.smallIcon ? undefined : props.size}/>)
            }
        }
        else {
            if (props.follower) {
                setFollowIcon(<FontAwesome prefix={'fas'} name={'fa-star'} id="sb-follow-button-icon"/>);
                setFollowText(<div className={followStyles.hiddenWhenSmall} id="sb-follow-button-text" aria-label="Click to cancel your subscription">Subscribed</div>);
            }
            else {
                setFollowIcon(<FontAwesome name={'fa-star'} prefix={'far'} id="sb-follow-button-icon"/>);
                setFollowText(<div className={followStyles.hiddenWhenSmall} id="sb-follow-button-text" aria-label="Click to subscribe">Subscribe</div>);
            }
        }
    },[props.follower, props.smallIcon])

    function handleClick(e) {
        e.preventDefault();
        if (userStore.id) {
            props.toggleFollowing();
        } else {
            modalStore.addModal({
                type: "login",
            });
        }
    }

    return (
        <>
            {props.symbolOnly ? <button
                onClick={handleClick}
                className={props.className}
                title={props.follower ? `Unsubscribe from ${props.organizationTitle}` : `Subscribe to ${props.organizationTitle}`}
                aria-label={props.follower ? `Unsubscribe from ${props.organizationTitle}` : `Subscribe to ${props.organizationTitle}`}>
                {followIcon}
            </button> :
            <button onClick={handleClick}
                    title={props.follower ? `Unsubscribe from ${props.organizationTitle}` : `Subscribe to ${props.organizationTitle}`}
                    aria-label={props.follower ? `Unsubscribe from ${props.organizationTitle}` : `Subscribe to ${props.organizationTitle}`}
                    className={`btn btn-default pull-right ${styles.classroomFollowFloatingButton}`}>
                {followIcon}
                {followText}
            </button>}
        </>
    );
}

export default FollowPageButton;
