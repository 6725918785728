import React, {useCallback, useContext, useRef, useState} from "react";
import Modal from "./Modal";
import styles from './styles/IconUpload.module.scss';
import {dataURItoFile} from "../../utils/MediaProcessor";
import {useMachine} from "@xstate/react";
import {FetchMachine} from "../machines/FetchMachine";
import FontAwesome from "../utilities/FontAwesome";
import {StoreContext} from "../../stores/StoreLoader";
import {getConfig} from "../../_configs/AppConfig";
import NotificationManager from "../notifications/NotificationManager";
import dynamic from "next/dynamic";
import {compressImage} from "../../utils/DataUtilities";
import {runInAction} from "mobx";
import {getDisplayName} from "../../pages/OrganizationSettings/AppearanceTab/ImageUpload";

const Cropper = dynamic(() => import("react-cropper"));

const {publicRuntimeConfig} = getConfig();

export function buildIconUrl(s3Key, size) {
    const iconUrl = JSON.stringify({
        bucket: publicRuntimeConfig.S3_MEDIA_BUCKET,
        key: s3Key,
        edits: {
            resize: {
                width: size,
                height: size,
                fit: "cover",
            }
        }
    });
    return publicRuntimeConfig.IMAGE_HANDLER_URL + "/" + Buffer.from(iconUrl).toString('base64');
}

export const IconUpload: React.FC<{
    index: number,
    modalObj: {
        setPreviewHref: (href: string) => void,
    },
    type: 'iconUpload' | 'faviconUpload',
}> = (props) => {
    const {modalStore, organizationStore} = useContext(StoreContext);

    const [image, setImage] = useState("");
    const [cropper, setCropper] = useState<null | Cropper>(null);
    const [current, send] = useMachine(FetchMachine);

    const imageHeight = useRef(0);
    const imageType = useRef(null);
    const imageName = useRef(null);
    const cropperRef = useRef(null);

    const acceptedFileTypes = props.type === "iconUpload" ? ".png" : "image/*"

    function replaceSvgWithPng(inputString: string) {
        return inputString.slice(0, -4) + ".png";
    }

    const onChange = useCallback((e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (!files || files.length === 0) return;
        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();

            img.onload = function () {
                if (props.type === "iconUpload" && (img.height < 512 || img.width < 512)) {
                    alert("Image is too small! Images must be at least 512x512 for web app functionality.");
                } else {
                    imageHeight.current = img.height;
                    setImage(img.src);
                }
            }

            img.src = reader.result as any;
        };
        imageType.current = files[0].type;
        imageName.current = files[0].name;
        reader.readAsDataURL(files[0]);
    }, []);

    const handleSubmit = useCallback(async (file) => {
        send("FETCH");

        try {
            const attribute = props.type === "iconUpload"
                ? "icon"
                : "favicon";
            const original = await compressImage(file,2)
            let fileName = file.name;
            if (file.name.endsWith(".svg")) {
                fileName = replaceSvgWithPng(file.name)
            }
            const result = await organizationStore.imageUpload(attribute, original, fileName);

            props.modalObj.setPreviewHref(result.media_url);
            runInAction(() => organizationStore.organization.json_data.settings.favicon = getDisplayName(result.media_url))

            send("FULFILL");

            modalStore.removeTop();
        } catch (e) {
            send("REJECT");
            NotificationManager.error(e.message)
        }
    }, []);

    return (
        <Modal index={props.index} className={styles.container}>
            <div>
                { props.type === "iconUpload" ? <><p>Upload and crop an image to be used as the site's web app icon used when the site is downloaded as a
                    web app and browser favicon.</p>
                    <p><span style={{fontWeight: 700}}>IMPORTANT:</span> Images must be at minimum 512px x 512px and of PNG
                        format.</p></>
                    : <p>Upload and crop an image to be used as the site's browser favicon.</p>
                }
                { props.type === "iconUpload" && <p>Web app functionality may not work across all browsers/devices if a smaller image is
                    used. <span style={{color: "red", fontWeight: 700}}>Support for updating icon images for previously downloaded web apps varies across browsers.
                Choose your icon carefully!</span>
                </p>}

                <label className={styles.button} htmlFor={"icon-input"}>Choose an Image</label>
                {image && <button
                    style={{marginLeft: 10, fontWeight: 700}}
                    disabled={current.value === "PENDING"}
                    className={styles.button}
                    onClick={() => {
                        if (cropper) {
                            const canvas = cropper.getCroppedCanvas();
                            if (canvas) {
                                const file = dataURItoFile(canvas.toDataURL(), imageName.current);
                                handleSubmit(file);
                            }
                        } else {
                            NotificationManager.error("An error has occurred. Please try again!");
                        }
                    }}>{current.value === "PENDING" ?
                    <span key={"pending"}>Saving... <FontAwesome prefix={'fas'} name={'fa-spinner'} spin/></span> :
                    <span key={"idle"}>Save</span>}</button>}
                <input id={"icon-input"} style={{display: "none"}} type="file" accept={acceptedFileTypes}
                       onChange={onChange}/>
                {image && <Cropper
                    aspectRatio={1}
                    ref={cropperRef}
                    src={image}
                    style={{height: 400, width: "100%"}}
                    autoCrop={true} // crop event is triggered before ready
                    minCropBoxHeight={Math.ceil(400 / (imageHeight.current / 512))} // set minimum width/height proportional to height of canvas
                    minCropBoxWidth={Math.ceil(400 / (imageHeight.current / 512))}
                    viewMode={1} // restrict the crop box not to exceed the size of the canvas
                    autoCropArea={1} // Define the automatic cropping area size (percentage)
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={setCropper}
                    guides={true}
                />}
            </div>
        </Modal>
    );
};

export default IconUpload;
