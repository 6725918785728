import { useContext } from 'react';
import * as React from 'react';
import {observer} from "mobx-react";
import classNames from "classnames";
import styles from "./styles/FooterCS.module.scss";
import {StoreContext} from "../../stores/StoreLoader";
import ClickableLink from "../utilities/ClickableLink";
import FontAwesome from "../utilities/FontAwesome";
import {convertFlatToTree} from "../../utils/DataUtilities";
import {isLink, isTopLevel} from "../modals/AdminModal/utilities";
import NavigationLink from "../header/navigation/NavigationLink";

const navColumnClassName = classNames({
    [styles.navColumn]: true,
});
const linkClassName = classNames({
    [styles.iconLink]: true,
    [styles.footerNavContainer]: true,
});

const NavigationColumn: React.FC<{
    href: string,
    title: string,
    navigationTree: Navigation[],
}> = (props) => {
    return <div className={navColumnClassName}>
        <h3><ClickableLink
            href={props.href}>{props.title}</ClickableLink></h3>
        <ul>
            {props.navigationTree
                .filter(n => isLink(n) && isTopLevel(n) && n.enabled)
                .map(n => <li key={n.id}>
                    <NavigationLink navigationObj={n} title={n.title}>
                        {n.icon && <FontAwesome ariaHidden={true} name={n.icon}/>} {n.title}
                    </NavigationLink>
                </li>)}
        </ul>
    </div>
}

// TODO: temporary solution until we get to defining block types in organizationStore, which is a can of worms for now
interface block {
    type: string,
    key: string,
    title: string,
    id: string,
}

const FooterNavColumn = observer(props => {
    const {organizationStore} = useContext(StoreContext);

    let columns = [<NavigationColumn
        key={'org-nav'}
        href={`${organizationStore.organization.primary_hostname.scheme}://${organizationStore.organization.primary_hostname.hostname}`}
        title={organizationStore.organization.type}
        navigationTree={organizationStore.navigationTree.filter(n => !n.is_district_link)}
    />];
    if (organizationStore.organization.type === 'school' && organizationStore.organization.parent_id !== "root") {
        const districtOrg = organizationStore.organization.district;
        const districtLink = organizationStore.navigationTree.find(n => n.is_district_link)?.url;
        columns.unshift(<NavigationColumn
            key={'district-nav'}
            href={districtLink}
            title={"DISTRICT"}
            navigationTree={convertFlatToTree(districtOrg.navigation)}
        />)
    }
    return <>
        {columns}
    </>
});

export default FooterNavColumn;
