import { useContext, useState, useRef, useLayoutEffect, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DropdownList from "./DropdownList";

import styles from "./styles/dropdown.module.scss";
import {StoreContext} from "../../../../stores/StoreLoader";
import {observer} from "mobx-react";
import SafeRouter from "../../../utilities/SafeRouter";
import {extractNumberFromPx} from "../../../../utils/StringUtilities";

const columnWidth = extractNumberFromPx(styles["column-width"]);
const outerPadding = extractNumberFromPx(styles["outer-padding"]);

const dropdownArrowClassName = classNames({
    [styles.dropdownArrow]: true,
});
const wrapperClassName = classNames({
    [styles.morphDropdownWrapper]: true,
});
const dropdownListClassName = classNames({
    [styles.dropdownList]: true,
});

const Dropdown = observer(props => {
    const {organizationStore, interfaceStore, userStore, i18nStore} = useContext(StoreContext);
    const [transform, setTransform] = useState("none");
    const [transformArrow, setTransformArrow] = useState(0);
    const [width, setWidth] = useState(0);
    const wrapperRef = useRef(null);

    const link = organizationStore.navigationTree.find(item => item.id === props.activeDropdown); // get selected link info (for children)
    const containerRect = props.containerRef.current?.getBoundingClientRect();
    const activeMainLinkRect = props.activeMainlink?.getBoundingClientRect();

    useLayoutEffect(() => {
        const height = wrapperRef.current.clientHeight;

        const offsetLeft = activeMainLinkRect.left - ((document.body.clientWidth - containerRect.width)/2);
        const offsetTop = activeMainLinkRect.top - containerRect.top; // offset of active link relative to meganav container
        const linkWidth = activeMainLinkRect.width;

        const activeChildrenLength = link.children.filter(c => c.enabled).length;

        const maxColumns = Math.floor((document.body.clientWidth - (outerPadding * 2))/columnWidth);
        const cols = Math.min(maxColumns, activeChildrenLength); // determine number of columns
        const width = columnWidth * cols + (outerPadding * 2); // determine width based on # of columns and add 30px padding on each side

        let transformArrow; // perform arrow transform here so dropdown and arrow are always in sync
        if (props.availableYBelow < (height) && props.availableYAbove > (height)) {
            transformArrow = `translateX(${offsetLeft + (linkWidth / 2) + 6}px) translateY(-${containerRect.height - offsetTop}px) rotate(45deg)`; // place above
        } else {
            transformArrow = `translateX(${offsetLeft + (linkWidth / 2) + 6}px) translateY(${-containerRect.height + offsetTop + activeMainLinkRect.height}px) rotate(45deg)`;
        }

        const transform = setHeightAndTranform(
            (offsetLeft + (linkWidth / 2)) - (width / 2),
            offsetTop,
            height,
            width,
            linkWidth,
        );

        setTransformArrow(transformArrow);
        setWidth(width);
        setTransform(transform);
    }, [wrapperRef.current, props.activeDropdown, interfaceStore.dimensions.width, containerRect]);

    const setHeightAndTranform = useCallback((nextLeft, nextTop, nextHeight, width, linkWidth) => {
        // set dropdown dimensions based on current dropdown instance requirements
        let translateX = "", translateY = `translateY(${-containerRect.height + nextTop + activeMainLinkRect.height}px)`;
        if (nextLeft + linkWidth + width > document.body.clientWidth) { // control for dropdown over screen on right
            const left = document.body.clientWidth - width - containerRect.x;
            translateX = `translateX(${left}px)`;
        } else if (nextLeft + containerRect.x < 0) { // control for dropdown over screen on left
            translateX = `translateX(-${containerRect.x}px)`;
        } else if (width >= document.body.clientWidth) { // control for dropdown larger that screen (sort of)
            translateX = `translateX(-${containerRect.x}px)`;
        } else { // otherwise, business as usual
            translateX = `translateX(${nextLeft}px)`;
        }

        if (props.availableYBelow < nextHeight && props.availableYAbove > nextHeight) {
            translateY = `translateY(-${nextHeight + containerRect.height - nextTop}px`; // switch between display above and display below
        }

        return `${translateX} ${translateY}`;
    }, [interfaceStore.dimensions.width, props.availableYAbove, props.availableYBelow, containerRect, activeMainLinkRect]);

    const dropdownClassName = classNames({
        [styles.dropdown]: true,
        [styles.moveLeft]: props.activeDropdown !== null,
        [styles.active]: props.activeDropdown === link.id,
        [styles.moveRight]: props.activeDropdown !== null,
        [styles.gallery]: link.type === 2,
        [styles.links]: link.type === 1,
        [styles.button]: link.type === 3,
    });

    const [focusIndex, setFocusIndex] = useState(null);
    const focusMainlink = useRef(props.keypress ? document.activeElement : null);

    const handleKeyDown = useCallback((e) => {
        const links = document.getElementsByClassName('nav-dropdown-item');
        if (e.which === 40 || e.which === 39) {
            e.preventDefault();
            // arrow down or arrow right
            if (link.type === 1 && e.which === 39) {
                // for list with header type dropdowns, right arrow transitions to next header
                setFocusIndex(curr => {
                    for (let i = curr + 1; i < links.length; i++) {
                        if (links.length >= i && links[i].classList.contains("nav-dropdown-header")) {
                            return i;
                        }
                    }
                    return curr;
                })
            } else {
                setFocusIndex(curr => {
                    return curr + 1 > links.length - 1 ? curr : curr + 1;
                });
            }
        } else if (e.which === 38 || e.which === 37) {
            e.preventDefault();
            // arrow up or arrow left
            if (link.type === 1 && e.which === 37) {
                setFocusIndex(curr => {
                    for (let i = curr - 1; i >= 0; i--) {
                        if (links.length >= i && links[i].classList.contains("nav-dropdown-header")) {
                            return i;
                        }
                    }
                    return curr;
                })
            } else {
                setFocusIndex(curr => {
                    return curr > 0 ? curr - 1 : curr;
                });
            }
        } else if (e.which === 9 && focusMainlink.current) {
            e.preventDefault();
            // tab, resume normal nav tabbing and close dropdown
            focusMainlink.current.focus();
            props.closeDropdown();
        }
    }, [focusIndex])

    useEffect(() => {
        if (props.keypress) {
            setFocusIndex(0);
        }
        document.addEventListener('keydown', handleKeyDown);

        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [wrapperRef.current]);

    useEffect(() => {
        const links = document.getElementsByClassName('nav-dropdown-item');
        if (links.length > 0 && links[focusIndex]) {
            links[focusIndex].focus();
        }
    }, [focusIndex]);

    return (
        <div className={wrapperClassName} ref={wrapperRef} key={link.id} style={{
          top: containerRect?.height || 0,
        }}>
            {width > 0 && <>
                <div key="arrow" className={dropdownArrowClassName} style={{
                    transform: transformArrow,
                }} aria-hidden="true"/>
                <div key="list" className={dropdownListClassName} style={{
                    width: width,
                    transform: transform,
                }} {...props.events}>
                    {userStore.isAdmin && <div style={{textAlign: "right", position: 'relative' }}>
                        <button style={{float: 'right',color: '#1700a1', transform: 'translateX(-120%)',boxShadow: '0 0 0 1px #ebeaea', borderRadius: '4px', padding: '2px 8px 2px 8px', position: 'absolute', whiteSpace: 'nowrap' }} onClick={()=> {
                            SafeRouter.push("/cms/admin/organizationSettings" +"#top-links-tab")
                            organizationStore.navigation.find(item => item.id === link.id).expanded = true;
                        }}>
                            Edit Navigation
                        </button>
                    </div>}
                    <div className={dropdownClassName}>
                        <a href="#" className={styles.label}>{link.title}</a>
                        <div className={styles.content}>
                            <ul role={"none"}>
                                {link.children
                                    .filter(z => z.enabled)
                                    .map(z => <DropdownList key={z.id} {...z} header={z.type === 1 ? z.title : false} maxHeight={(interfaceStore.dimensions.height - 240)/2}/>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </>}
        </div>
    );
});

Dropdown.propTypes = {
    activeDropdown: PropTypes.number.isRequired,
    events: PropTypes.object.isRequired,
};


export default Dropdown;
