import {useContext} from "react";
import {StoreContext} from "../stores/StoreLoader";
import WatsonApi from "../backends/WatsonApi";
import NotificationManager from "../components/notifications/NotificationManager";

/*
Returns a function that allows the user to follow / unfollow the page they are currently on.
 */
export function useToggleFollowPage() {
    const {organizationStore, userStore} = useContext(StoreContext);

    const toggleFollowing = async () => {
        try {
            const client = await WatsonApi();
            await client.apis.toggle_follow.toggle_follow_toggle_follow_organization({
                "itemname": organizationStore.currentOrganization.id,
            });
            const newState = !userStore.follower;
            userStore.setPermissions({
                follower: newState,
            })
            if (newState === true) {
                NotificationManager.success("You are now subscribed to this organization.");
            } else {
                NotificationManager.success("You are no longer subscribed to this organization.");
            }
        } catch (e) {
            NotificationManager.error(JSON.parse(e.response.text));
        }
    }

    return toggleFollowing;
}
