import {toJS} from "mobx";
import * as React from 'react';

import { withLocaleInURI } from "./i18nURI";
const { allLocaleStringsLowerCase, supportedLocales, universalDefaultLocale } = require("./SupportedLocales");

/*
 * This function *must* be a function that returns an array of React Nodes, not a React Component.
 * The return value of this function is used to populate direct descendents of the next/head component,
 * and that has restrictions about how deep children can be.  Additionally, the allStores value passed
 * into this function must be from StoreContext so we get the right instance values.  This function
 * is not an observed function, but the caller is...so the function should get called at every rerender
 * of the parent.
 */

export default function i18nHeadTags(allStores): Array<React.ReactNode> {
    const {interfaceStore, i18nStore} = allStores;

    const currentUrl = interfaceStore.currentFullUrl;

    // TODO: when we decide on a configuration setting, the defaultLocale
    //     should be the organization's "source locale"

    const currentLocaleString = i18nStore.currentOrgSupportedLocale as string;
    const defaultLocale = i18nStore.orgDefaultLocale;

    // This will be the organization's source locale when we define that
    const defaultLanguageUrl = withLocaleInURI(currentUrl, defaultLocale);

    // https://ogp.me/#optional
    // https://developers.google.com/search/docs/advanced/crawling/localized-versions#expandable-1
    // https://blogs.bing.com/webmaster/2011/03/01/how-to-tell-bing-your-websites-country-and-language/

    const i18nTags = [
        <link key="canonical-link" rel="canonical" href={defaultLanguageUrl} />,
        <link key="hreflang-default" hrefLang="x-default" rel="alternate" href={defaultLanguageUrl} />,
        <meta key="content-lang" httpEquiv="content-language" content={currentLocaleString} />,
        <meta key="og-url" property="og:url" content={defaultLanguageUrl} />,
        <meta key="og-locale" property="og:locale" content={currentLocaleString} />,
    ];

    let locale, localeObj, localeUrl;
    i18nStore.organizationSupportedLocales.forEach((l) => {
        locale = allLocaleStringsLowerCase.get(l.toLowerCase())
        localeObj = supportedLocales.get(locale);
        localeObj.acceptedLocales.forEach((acceptedLocale) => {
            localeUrl = withLocaleInURI(currentUrl, acceptedLocale);
            i18nTags.push(<link key={"hreflang-" + acceptedLocale} rel="alternate" hrefLang={acceptedLocale} href={localeUrl}/>);
            i18nTags.push(<meta key={"og-locale-alt-" + acceptedLocale} property="og:locale:alternate" content={acceptedLocale} />);
        });
    });

    return i18nTags;
}
