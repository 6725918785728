export function isFolder(node: Navigation): boolean {
    // IMPORTANT! Don't use this where we work with both top links and quicklinks because it will
    // return true for top level list heading as well
    return node.type === 1;
}

export function isTopLevel(node: Navigation): boolean {
    return node.path.length === 36;
}

export function isTopLevelListHeading(node: Navigation): boolean {
    return node.type === 1 && isTopLevel(node);
}

export function isTopLevelIconHeading(node: Navigation): boolean {
    return node.type === 2 && isTopLevel(node);
}

export function isSecondLevelListHeading(node: Navigation): boolean {
    return node.type === 1 && node.path.length === 40;
}

export function isLink(node: Navigation): boolean {
    return node.type === 3 || isIconLink(node) || isModalLink(node);
}

export function isIconLink(node: Navigation): boolean {
    return node.type === 2 && node.path.length === 40;
}

export function isListLink(node: Navigation): boolean {
    return (node.type === 3 || isModalLink(node)) && node.path.length === 44;
}

export function isInternalLink(node: Navigation): boolean {
    return isLink(node) && !!node.organization_id;
}

export function isExternalLink(node: Navigation): boolean {
    return isLink(node) && !isInternalLink(node);
}

export function isModalLink(node: Navigation): boolean {
    return node.type === 4;
}

export function isShortcut(node: Navigation): boolean {
    return node.type === 0;
}